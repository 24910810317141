<template>
  <div class="bk-blog-area">
    <div class="black-bg-wrapper bg_image--4 section-pt-xl pb--320 bg-blogs-part">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center white-text wow move-up">
              <h3 class="text-white">LATEST NEWS</h3>
              <h2>From our News and Blogs</h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
        class="blog-wrapper pl--230 pr--230 pr_lp--100 pl_lp--100 pl_lg--100 pr_lg--100 pl_md--50 pr_md--50 pl_sm--30 pr_sm--30 pb--50">
      <div class="row row--0 space_dec--250">
        <div v-for="blog in blogs" v-bind:key="blog.slug" class="col-xl-4 col-md-6 wow move-up mt_sm--40">
          <BlogPostThree :blog="blog" addClass="blog-standard blog-grid--modern mt-6 pl-2 pr-2" readMoreButton="true"/>
        </div>
      </div>
    </div>

    <div class="row align-items-center pb--50 pt-5">
      <div class="col-lg-12 col-12">
        <div class="call-content vertical-call-toaction text-center">
          <div class="call-btn text-center">
            <router-link to="/blogs"
                         class="brook-btn bk-btn-theme text-theme btn-sd-size btn-rounded nuxt-link-active bg-gradient-orange">
              Find out more
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BlogPostThree from "../BlogPostThree"
import {blogsCollection} from "@/firebase";

export default {
  components: {
    BlogPostThree,
  },
  data() {
    return {
      blogs: [],
      whereConditions: [
        {
          key: 'active',
          operator: '==',
          value: true,
        },
        {
          key: 'type',
          operator: '==',
          value: 'blog',
        }
      ]
    }
  },
  firestore: {
    blogs: blogsCollection.where("active", "==", true)
        .orderBy('date', 'desc')
        .limit(6)
  }
};
</script>

<style>
.bg-blogs-part {
  background-color: #282828;
}
</style>