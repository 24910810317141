<template>
  <div>
    <base-header type="gradient-orange" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow"
               :class="type === 'dark' ? 'bg-default': ''">
            <div class="card-header border-0"
                 :class="type === 'dark' ? 'bg-transparent': ''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Headers
                  </h3>
                </div>
                <div class="col text-right">
                  <router-link to="/admin/headers/create" class="btn btn-primary"
                  ><i class="ni ni-fat-add align-middle"></i> Create new
                    header
                  </router-link
                  >
                </div>
              </div>
            </div>


            <div class="table-responsive">
              <base-table class="table align-items-center table-flush"
                          :class="type === 'dark' ? 'table-dark': ''"
                          :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                          tbody-classes="list"
                          :data="this.headers">

                <template slot="columns">
                  <th>Image</th>
                  <th>Title</th>
                  <th>Created</th>
                  <th class="text-center">Active</th>
                  <th class="text-center">#</th>
                </template>

                <template slot-scope="{row}">
                  <td class="budget">
                    <img alt="Image placeholder" :src="row.imageUrl" width="100">
                  </td>

                  <th scope="row">
                    <div class="media align-items-center">
                      <div class="media-body">
                        <span class="name mb-0 text-sm">{{ row.title }}</span>
                      </div>
                    </div>
                  </th>

                  <td>
                    <badge class="badge-dot mr-4">
                      <div><small>Created by:</small> {{ row.user.firstName }}</div>
                      <div><small>Date:</small> {{ moment(row.created.toDate()).format('DD.MM.YYYY') }}</div>
                    </badge>
                  </td>
                  <td class="text-center">
                    <div class="avatar-group">
                      <toggle-button :value="row.active"
                                     color="#2BCEA0"
                                     :sync="true"
                                     :labels="true"
                                     @change="toggleActive(row.id, row.active)"/>
                    </div>
                  </td>
                  <td class="text-center">
                    <div>
                      <router-link :to="`headers/edit/${row.id}`">
                        <button class="btn btn-sm ni ni-settings btn-primary">
                        </button>
                      </router-link>
                      <button @click="passIdForDeletion(row)" v-b-modal.modal-1
                              class="btn btn-sm ni ni-fat-remove btn-danger ml-1"></button>
                    </div>
                  </td>
                </template>
              </base-table>
            </div>
          </div>
          <b-modal hide-footer id="modal-1" ref="my-modal" title="Remove header">
            <p v-if="this.headerForDeletion" class="mt-0 mb-4">Are you sure you want to remove header: '<b>
              {{ this.headerForDeletion.title }} </b>'?</p>
            <button type="button" class="btn btn-danger" @click="removePartner">Remove</button>
            <b-button @click="hideModal">Cancel</b-button>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {headersCollection, storageRef} from "@/firebase";

export default {
  name: "headers",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      headers: [],
      headerForDeletion: null
    };
  },
  firestore: {
    headers: headersCollection.orderBy('created','desc')
  },
  methods: {
    passIdForDeletion(row) {
      this.headerForDeletion = row;
      this.$refs['my-modal'].show();
    },
    removePartner() {
      headersCollection.doc(this.headerForDeletion.id).get().then((doc) => {
        if (doc.exists) {
          let image = doc.data().image
          storageRef.child("headers/" + image).delete()
          headersCollection.doc(this.headerForDeletion.id).delete();
        }
      })
      this.$refs['my-modal'].hide();
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleActive(id, active) {
      headersCollection.doc(id).update({active: !active});
    }
  }
};
</script>

<style scoped>
@import "~bootstrap-vue/dist/bootstrap-vue.min.css";
</style>