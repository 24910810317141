<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-orange opacity-8"></span>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card>
            <div slot="header" class="border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Create new Partner</h3>
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">Partner information</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <base-input alternative=""
                                  label="Title"
                                  placeholder="Partner name..."
                                  input-classes="form-control-alternative"
                                  v-model="partner.title"
                                  type="text"
                                  ref="titleRef"
                                  required
                      />
                    </div>
                    <div class="col-lg-12">
                      <base-input alternative=""
                                  label="Link for official website"
                                  placeholder="www.example.com"
                                  input-classes="form-control-alternative"
                                  v-model="partner.url"
                                  ref="urlRef"
                                  required
                      />
                    </div>
                    <div class="col-lg-3">
                      <base-input alternative=""
                                  label="Show partner at position:"
                                  placeholder=""
                                  input-classes="form-control-alternative"
                                  type="number"
                                  v-model.number="partner.order"
                                  ref="orderRef"
                                  required
                      />
                      <h6 class="text-muted">Note: smaller number will show partner higher in the list!</h6>
                    </div>
                  </div>
                </div>
                <hr class="my-4"/>
                <!-- Address -->
                <h6 class="heading-small text-muted mb-4">Partners logo</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <cropper
                          class="cropper-aria"
                          :stencil-props="{aspectRatio: 1/1}"
                          :src="image.src"
                          ref="cropper"
                      ></cropper>
                      <!-- <div v-if="image.uploadProgress > 0">
                        <div>
                          <small>{{ image.uploadStatus }}</small>
                        </div>
                        <loading-progress
                            :progress="image.uploadProgress"
                            :indeterminate="false"
                            :counter-clockwise="false"
                            :hide-background="false"
                            shape="line"
                            size="200"
                            width="200"
                            height="6"
                        />
                      </div> -->
                      <span class="mt-3 btn btn-primary" @click="$refs.file.click()" required><input
                          style="display:none" type="file" ref="file"
                          @change="loadImage($event)"
                          accept="image/*"> Load image</span>
                    </div>
                  </div>
                </div>
                <hr class="my-4"/>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <button class="btn btn-success" @click="formValidation()">Save</button>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" ref="error-modal" title="Errors">
      <p v-if="this.errors.length">
        <b class="text-danger">Please correct the following error(s):</b>
      <ul>
        <li v-bind:key="error.id" v-for="error in errors">{{ error }}</li>
      </ul>
      </p>
    </b-modal>

  </div>

</template>
<script>
import {partnersCollection, storageRef, usersCollection} from "@/firebase";
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import moment from 'moment';
import firebase from 'firebase'
import router from '@/router';

export default {
  name: "create",
  components: {
    Cropper
  },
  data() {
    return {
      image: {
        src: ''
      },
      partner: {
        title: '',
        url: '',
        image: '',
        imageUrl: '',
        created: '',
        user: '',
        order: 0,
        active: false
      },
      errors: []
    }
  },
  methods: {
    loadImage(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.image.src = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    formValidation() {
      //lista mora biti prazna prije nego sto se odradi validacija, inace se vracaju stare - prethodne   greske
      this.errors = []
      if (this.partner.title.length > 100) {
        this.errors.push("Title can't have more than 100 chars")
      }
      if (this.partner.title === "") {
        this.errors.push("You can't leave title empty!")
      }
      if (!this.partner.url) {
        this.errors.push("You can't leave url empty!")
      }
      // !0 je true zbog toga provjeravamo da li je 0, ako nije error, ako jeste nista
      if (!this.partner.order && this.partner.order!==0){
        this.errors.push("You can't leave order number empty!")
      }
      if (!this.$refs.file.files[0]) {
        this.errors.push("You have to add an image!")
      }
      if (this.errors.length) {
        this.$refs['error-modal'].show()
        return;
      }
      this.uploadImageAndSaveDoc()
    },
    uploadImageAndSaveDoc() {
      var slugify = require('slugify')
      this.partner.image = slugify(this.partner.title) + '-' + moment().unix() + ".jpg"
      const {canvas} = this.$refs.cropper.getResult();
      if (canvas) {
        canvas.toBlob(blob => {
          let uploadTask = storageRef.child('partners/' + this.partner.image).put(blob);
          uploadTask.on('state_changed',
              (snapshot) => {
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    break;
                }
              },
              (error) => {
                console.log(error);
              },
              () => {
                this.save(uploadTask)
              }
          );
        }, 'image/jpeg');
      }
    },
    save(uploadTask) {
      let task = uploadTask.snapshot.ref.getDownloadURL();
      task.then((url) => {
        this.partner.imageUrl = url;
        this.partner.user = usersCollection.doc(this.$store.state.userProfile.uid);
        this.partner.created = firebase.firestore.Timestamp.fromDate(new Date());
        partnersCollection.doc().set(this.partner).then(() => {
          router.push('/admin/partners');
        }).catch((error) => {
          console.error("Error adding document: ", error);
        });
      })
    },
  },
};
</script>
<style>
.cropper-aria {
  border: solid 1px #EEE;
  height: 300px;
  width: 100%;
}

.btn input {
  display: none;
}
</style>