<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-orange opacity-8"></span>
    </base-header>
    <div class="container-fluid mt--5">
      <div class="card shadow">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">
                Notification Users
              </h3>
            </div>
            <div class="col text-right">
              <button class="btn btn-primary" @click="openCreateUserModal">
                <i class="ni ni-fat-add align-middle"></i> Create new
              </button>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <base-table class="table align-items-center table-flush" :data="this.users">
            <template slot="columns">
              <th>Full name</th>
              <th>Email</th>
              <th>Created</th>
              <th>Active</th>
              <th class="text-center">#</th>
            </template>

            <template slot-scope="{row}">
              <td>
                <div>{{ row.firstName }} {{ row.lastName }}</div>
              </td>
              <td>
                <div>{{ row.email }}</div>
              </td>
              <td>
                <div><small>Created by:</small> {{ row.user.firstName }}</div>
                <div><small>Date:</small> {{ moment(row.created.toDate()).format('DD.MM.YYYY') }}</div>
              </td>
              <td>
                <div class="d-flex align-items-center text-center">
                  <toggle-button :value="row.active"
                                 color="#2BCEA0"
                                 :sync="true"
                                 :labels="true"
                                 @change="toggleActive(row.id,row.active)"/>
                </div>
              </td>
              <td>
                <div class="text-center">
                  <button class="btn btn-sm btn-primary ni ni-settings" @click="openEditModal(row)">
                  </button>
                  <button class="btn btn-sm btn-danger ni ni-fat-remove ml-1" id="show-btn"
                          @click="openDeleteModal(row)"></button>
                </div>
              </td>
            </template>

          </base-table>


        </div>
      </div>
    </div>
    <b-modal hide-footer id="create-modal" ref="create-modal" title="Notification User Info">
      <form @submit.prevent>
        <div class="row">
          <div class="col-lg-6">

            <base-input alternative=""
                        label="First Name"
                        placeholder="Tim"
                        input-classes="form-control-alternative"
                        v-model="notifyUser.firstName"
                        required
            />
            <div class="error-info-lbl-usr" v-if="errors.firstName">{{ errors.firstName }}</div>
          </div>
          <div class="col-lg-6">
            <base-input alternative=""
                        label="Last Name"
                        placeholder="Wolf"
                        input-classes="form-control-alternative"
                        v-model="notifyUser.lastName"
                        required
            />
            <div class="error-info-lbl-usr" v-if="errors.lastName">{{ errors.lastName }}</div>

          </div>
          <div class="col-lg-12 pb-4">
            <base-input alternative=""
                        label="Email"
                        placeholder="example@example.com"
                        input-classes="form-control-alternative"
                        v-model="notifyUser.email"
                        type="email"
                        required
            />
            <div class="error-info-lbl-usr" v-if="errors.email">{{ errors.email }}</div>

          </div>
        </div>
        <button type="button" class="btn btn-primary" @click="saveUser">Save User</button>
        <b-button @click="hideCreateModal">Cancel</b-button>
      </form>
    </b-modal>

    <b-modal hide-footer id="remove-modal" ref="remove-modal" title="Remove notification user">
      <p v-if="this.userForDeletion" class="mt-0 mb-4">Are you sure you want to remove this user:
        '<b>{{ this.userForDeletion.firstName }} {{ this.userForDeletion.lastName }}</b>'?</p>
      <button type="button" class="btn btn-danger" @click="removeUser">Remove</button>
      <b-button @click="hideRemoveModal">Cancel</b-button>
    </b-modal>
  </div>
</template>
<script>
import {notificationUsersCollection, usersCollection} from "@/firebase"
import firebase from "firebase";

export default {
  name: 'NotificationUsers',
  data() {
    return {
      editMode: false,
      users: [],
      notifyUser: {
        firstName: '',
        lastName: '',
        email: '',
        created: '',
        user: '',
        active: true
      },
      errors: {
        firstName: null,
        lastName: null,
        email: null,
      },
      active: null,
      userForDeletion: null
    }
  },
  firestore: {
    users: notificationUsersCollection
  },
  methods: {
    openCreateUserModal() {
      this.editMode = false;
      this.notifyUser = {
        firstName: '',
        lastName: '',
        email: '',
        created: '',
        user: '',
        active: true
      }
      this.clearErrors();
      this.$refs['create-modal'].show();
    },
    hideCreateModal() {
      this.clearErrors();
      this.$refs['create-modal'].hide()
    },
    toggleActive(id, active) {
      notificationUsersCollection.doc(id).update({active: !active})
    },
    openEditModal(row) {
      this.editMode = true;
      this.notifyUser = row;
      this.$refs['create-modal'].show();
    },
    saveUser() {
      this.clearErrors();

      if (this.notifyUser.firstName === '') {
        this.errors.firstName = 'First Name is required.';
      }

      if (this.notifyUser.lastName === '') {
        this.errors.lastName = 'last Name is required.';
      }

      if (this.notifyUser.email === '') {
        this.errors.email = 'Email is required.';
      }

      if (Object.keys(this.errors).length > 0) {
        return;
      }

      this.notifyUser.user = usersCollection.doc(this.$store.state.userProfile.uid)
      this.notifyUser.created = firebase.firestore.Timestamp.fromDate(new Date())
      if (this.editMode) {
        notificationUsersCollection.doc(this.notifyUser.id).update(this.notifyUser)
        this.hideCreateModal();
      } else {
        notificationUsersCollection.add(this.notifyUser);
        this.hideCreateModal();
      }
    },
    removeUser() {
      notificationUsersCollection.doc(this.userForDeletion.id).delete();
      this.hideRemoveModal();
    },
    hideRemoveModal() {
      this.$refs['remove-modal'].hide()
      this.userForDeletion = null;
    },
    openDeleteModal(row) {
      this.userForDeletion = row;
      this.$refs['remove-modal'].show();
    },
    clearErrors() {
      this.errors = {};
    }
  }
};
</script>
<style>
@import "~bootstrap-vue/dist/bootstrap-vue.min.css";

.width-100 {
  width: 100px;
}

.error-info-lbl-usr {
  color: #920404;
  font-size: 12px;
  margin-top: -19px;
}
</style>