<template>
  <div class="row mt--30" v-if="goals.list.length > 0">
    <div class="col-lg-6 col-md-6 wow move-up service service--1 text-center mt--30">
      <div class="icons">
        <img src="../static/img/goals/partnership.svg">
      </div>
      <div class="content">
        <h4>Objective 1</h4>
        <p class="objective-text">{{ goals.list[0] }}</p>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 wow move-up service service--1 text-center mt--30">
      <div class="icons">
        <img src="../static/img/goals/globe.svg">
      </div>
      <div class="content">
        <h4>Objective 2 </h4>
        <p class="objective-text">{{ goals.list[1] }}</p>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 wow move-up service service--1 text-center mt--30">
      <div class="icons">
        <img src="../static/img/goals/digitalization.svg">
      </div>
      <div class="content">
        <h4>Objective 3</h4>
        <p class="objective-text">{{ goals.list[2] }}</p>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 wow move-up service service--1 text-center mt--30">
      <div class="icons">
        <img src="../static/img/goals/rocket.svg">
      </div>
      <div class="content">
        <h4>Objective 4</h4>
        <p class="objective-text">{{ goals.list[3] }}</p>
      </div>
    </div>
  </div>


</template>

<script>
import {configurationsCollection} from "@/firebase";

export default {
  data() {
    return {
      goals: {
        list: []
      }
    }
  },
  firestore: {
    goals: configurationsCollection.doc('goals')
  }
};
</script>

<style scoped>
.service .icons img {
  max-height: 91px;
}

.service.service--1::before {
  top: unset !important;
}

.objective-text {
  line-height: normal !important;
  font-weight: 500 !important;
}

</style>