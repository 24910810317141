<template>
  <div ref="scrollTo" class="main-container">
    <Header/>
    <OffCanvasMobileMenu/>
    <HeroBusiness/>
    <overallObjectives/>

    <div class="brook-icon-boxes-area ptb--120 ptb-md--80 ptb-sm--60 bg_color--1">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="brook-section-title text-center">
              <h5 style="color:#FF4E00" class="mb-5 text-uppercase">Specific objectives</h5>
              <h3 class="heading heading-h3 mt-5 letter-spacing-2">Dignest supports modernization<br> of heis in
                Montenegro</h3>
            </div>
          </div>
        </div>
        <ServiceItemOne/>
      </div>
    </div>
    <BlogOne/>
    <BrandLogoCarousel/>
    <PortfolioOne/>
    <Footer/>

  </div>
</template>

<script>

export default {
  components: {
    Header: () => import('../components/Header'),
    OffCanvasMobileMenu: () => import('../components/OffCanvasMobileMenu'),
    HeroBusiness: () => import('../components/HeroBusiness'),
    ServiceItemOne: () => import('../components/ServiceItemOne'),
    overallObjectives: () => import ('../components/overallObjectives'),
    PortfolioOne: () => import('../components/sections/PortfolioOne'),
    BrandLogoCarousel: () => import('../components/BrandLogoCarousel'),
    BlogOne: () => import('../components/sections/BlogOne'),
    Footer: () => import('../components/Footer'),
  },

  mounted() {
    document.body.classList.add('template-color-1', 'template-font-2', 'refresh-page')
    this.$smoothScroll({
      scrollTo: this.$refs.scrollTo,
      duration: 400,
    });
  }
};
</script>
<style>
.letter-spacing-2 {
  letter-spacing: 2px !important;
}

.overallObjectiveText {
  padding: 25px 150px 0px 150px;
  text-align: center;
}

.orangeColor {
  color: #FF4E00;
}
</style>