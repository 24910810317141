<template>
  <div class="main-container">
    <Header/>
    <OffCanvasMobileMenu/>
    <Breadcrumb :items="items" :title="this.gallery.title"/>
    <div v-if="gallery.count === 0" class="container text-center ">
      <div class="no-image-box row text-center justify-content-center mt-5">
        <div>
          <img src="../../static/img/gallery/noimages.svg">
        </div>
        <div class="d-block text-center mt-5"> There is no images on this page yet. Please come back later!
          <router-link to="/galleries" class="link">Try another one</router-link>
        </div>
      </div>
    </div>

    <CoolLightBox
        :items="gallery.imagesUrls"
        :index="index"
        :effect="'fade'"
        @close="index = null">
    </CoolLightBox>
    <div class="container m-auto myMainContainer">
      <div class="images-wrapper row">
        <div
            class="image col-12 col-md-6 col-lg-4 mt-2"
            v-for="(image, imageIndex) in gallery.imagesUrls"
            :key="imageIndex"
            @click="index = imageIndex"
        ><img :src="image" alt=""></div>
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
import {galleriesCollection} from "@/firebase";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  components: {
    Header: () => import('../../components/Header'),
    OffCanvasMobileMenu: () => import('../../components/OffCanvasMobileMenu'),
    Breadcrumb: () => import('../../components/Breadcrumb'),
    Footer: () => import('../../components/Footer'),
    CoolLightBox,
  },
  data() {
    return {
      id: this.$route.params.id,
      hasImages: true,
      gallery: {
        imagesUrls: [],
        count: 0
      },
      items: [
        {
          text: 'Home',
          to: "/"
        },
        {
          text: 'Galleries',
          to: "/galleries"
        }
      ],
      index: null
    }
  },
  created() {
    galleriesCollection.doc(this.id).get().then((doc) => {
      if (doc.exists) {
        this.gallery = doc.data()
      }
    })
  },
  computed: {
    rows() {
      return this.gallery.imagesUrls.length
    }
  }
}
</script>

<style scoped>
.myMainContainer {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.container:nth-child(2) {
  margin-top: 50px;
}

.image {
  cursor: pointer
}

.no-image-box img {
  width: 50%;
}

.no-image-box .link {
  color: #ffb944;
}
</style>