<template>
  <div class="blog-grid blog-standard" :class="addClass">
    <div class="post-thumb">
      <router-link :to="`/blogs/${blog.slug}`">
        <img :src="blog.imageUrl" :alt="blog.title">
      </router-link>
    </div>
    <div class="post-content text-center">
      <div class="post-inner">
        <div class="post-meta mb--10">
          <div class="post-date">
            {{ blog.date }}
          </div>
          <div class="post-category">
            <router-link class="text-uppercase" to="/">
              {{ blog.type }}
            </router-link>
          </div>
        </div>
        <h5 class="min-h heading heading-h5">
          <router-link :to="`/blogs/${blog.slug}`">
            {{ blog.title | str_limit(50) }}
          </router-link>
        </h5>
        <router-link :to="`/blogs/${blog.slug}`" class="post-read-more bg-gradient-orange"
                     v-show="readMoreButton"></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["blog", "addClass", "readMoreButton"]
};
</script>

<style>
.min-h {
  min-height: 50px;
}
</style>