<template>
  <div>
    <div class="container">
      <div class="row mtn--30">
        <div v-for="gallery in galleries" v-bind:key="gallery.id" class="col-lg-4 col-sm-6 move-up wow mt--30">
          <router-link :to="`galleries/g/${gallery.id}`">
            <div class="portfolio portfolio_style--1">
              <div class="thumb">
                <img :src="gallery.featuredImgUrl" alt="Portfolio Image">
              </div>
              <div class="port-overlay-info">
                <div class="hover-action">
                  <h3 class="post-overlay-title">
                    {{ gallery.title }}
                  </h3>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {galleriesCollection} from "@/firebase";

export default {
  data() {
    return {
      galleries: []
    }
  },
  firestore: {
    galleries: galleriesCollection.where('active', '==', true).orderBy('created', 'desc').limit(6)
  },
};
</script>

<style scoped>
.row {
  margin-top: -200px;
}

.port-overlay-info {
  background-color: #FF4E00 !important;
}
</style>