<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <div class="mb-4">
              <router-link to="/">
                <img src="../../admin/assets/logo.png" class="logo-img" alt="Dignest">
              </router-link>
            </div>
            <small>Sign in with credentials</small>
          </div>
          <form role="form">
            <base-input class="input-group-alternative mb-3"
                        placeholder="Email"
                        addon-left-icon="ni ni-email-83"
                        v-model="model.email">
            </base-input>

            <base-input class="input-group-alternative"
                        placeholder="Password"
                        type="password"
                        addon-left-icon="ni ni-lock-circle-open"
                        v-model="model.password" @keyup.enter.native="login()">
            </base-input>
            <div class="alert alert-danger" v-if="loginErrorMessage">{{ loginErrorMessage }}</div>
            <div class="text-center">
              <base-button @click="login()" type="primary" class="my-4">Sign in</base-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {auth} from "@/firebase";

export default {
  name: 'login',
  computed: {
    loginErrorMessage() {
      return this.$store.state.login.error.message;
    }
  },
  data() {
    return {
      errorMsg: null,
      model: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    login() {
      this.$store.dispatch('login', {
        email: this.model.email,
        password: this.model.password
      })
    }
  }, created() {
    auth.onAuthStateChanged(user => {
      if (user) {
        this.$store.dispatch('fetchUserProfile', user)
      }
    })
  }
}

</script>
<style>
.logo-img {
  width: 250px;
}
</style>
