<template>
  <div v-if="overall.content">
    <div class="brook-icon-boxes-area pt--120 ptb-md--80 ptb-sm--60 bg_color--1">
      <h5 class="mb-5 text-uppercase text-center orangeColor">Overall objectives</h5>
      <p class="objective-text" v-html="overall.content"></p>
    </div>
  </div>
</template>

<script>
import {textPagesCollection} from "@/firebase";

export default {
  data() {
    return {
      overall: {
        content: null
      }
    }
  },
  created() {
    textPagesCollection.where('active', '==', true)
        .where('slug', '==', 'overall-objectives')
        .get()
        .then(async (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.overall = doc.data();
          });
        });
  }
}
</script>

<style scoped>
.objective-text {
  line-height: normal !important;
  text-align: center;
  margin: auto;
  padding: 0% 12% 0% 12%;
  font-size: 18px;
  font-weight: 600;

}
</style>
