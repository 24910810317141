<template>
  <div class="mobile-navigation">
    <nav class="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/participants">Participants</router-link>
        </li>
        <li class="menu-item-has-children">
          <router-link to="/page/project">Project</router-link>
          <ul class="sub-menu">
            <li>
              <router-link to="/page/about"><span>About the project</span></router-link>
            </li>
            <li>
              <router-link to="/working-packages"><span>Working Packages</span></router-link>
            </li>
            <li>
              <router-link to="/page/project-boards"><span>Project Boards</span></router-link>
            </li>
          </ul>
        </li>
        <li class="menu-item-has-children">
          <router-link to="/activities">Activities</router-link>
          <ul class="sub-menu">
            <li>
              <router-link
                  to="/page/wp1-analysis-and-road-mapping-supporting-digitization-of-industry-in-montenegro">
                <span>WP1- ANALYSIS AND ROAD-MAPPING: SUPPORTING DIGITIZATION OF INDUSTRY IN MONTENEGRO</span>
              </router-link>
            </li>
            <li>
              <router-link to="/page/wp2-human-capacity-building-in-digitization-of-industry4.0">
                <span>WP2- HUMAN CAPACITY BUILDING IN DIGITIZATION OF INDUSTRY4.0</span>
              </router-link>
            </li>
            <li>
              <router-link to="/page/wp3-development-of-mne-academic-digital-innovation-hub-and-networking">
                <span>WP3- DEVELOPMENT OF MNE ACADEMIC DIGITAL INNOVATION HUB AND NETWORKING</span>
              </router-link>
            </li>
            <li>
              <router-link to="/page/wp4-piloting-and-evaluation">
                <span>WP4- PILOTING AND EVALUATION</span>
              </router-link>
            </li>
            <li>
              <router-link to="/page/quality-control-and-monitoring"><span>QUALITY CONTROL AND MONITORING</span>
              </router-link>
            </li>
            <li>
              <router-link to="/page/dissemination-and-exploitation"><span>DISSEMINATION AND EXPLOITATION</span>
              </router-link>
            </li>
            <li>
              <router-link to="/coordination-and-management"><span>COORDINATION AND MANAGEMENT</span>
              </router-link>
            </li>

          </ul>
        </li>
        <li>
        <li class="menu-item-has-children">
          <router-link to="#">Newsletters</router-link>
          <ul class="sub-menu">
            <li v-for="news in newsletters" :key="news.slug">
              <router-link
                  :to="`newsletters/${news.slug}`">
                <span>{{ news.title }}</span>
              </router-link>
            </li>

          </ul>
        </li>
        <li>
          <router-link to="/downloads">Downloads</router-link>
        </li>
        <li class="menu-item-has-children">
          <router-link to="#">National Conferences</router-link>
          <ul class="sub-menu">
            <li>
              <router-link to="/page/a">
                <span>1st National Conference</span>
              </router-link>
            </li>
            <li>
              <router-link to="/page/2nd-national-conference:-challenges-of-digital-transformation">
                <span>2nd National Conference</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li style="width: 120px;">
          <a target="_blank" href="https://ec.europa.eu/"><img src="../static/img/nav/ErasmusLogo.svg"></a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {newsletterCollection} from "@/firebase";

export default {
  name: 'MobileNavMenu',
  data() {
    return {
      newsletters: []
    }
  },
  firestore: {
    newsletters: newsletterCollection.where('active', '==', true).orderBy('order', 'desc')
  },
  mounted() {
    const offCanvasNav = document.querySelector('#offcanvas-navigation');
    const offCanvasNavSubMenu = offCanvasNav.querySelectorAll('.sub-menu');
    const anchorLinks = offCanvasNav.querySelectorAll('a');

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='menu-expand'><i></i></span>");
    }

    const menuExpand = offCanvasNav.querySelectorAll('.menu-expand');
    const numMenuExpand = menuExpand.length;

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", (e) => {
        sideMenuExpand(e)
      });
    }

    for (let i = 0; i < anchorLinks.length; i++) {
      anchorLinks[i].addEventListener("click", () => {
        closeMobileMenu()
      });
    }

    const sideMenuExpand = (e) => {
      e.currentTarget.parentElement.classList.toggle('active');
    }
    const closeMobileMenu = () => {
      const offcanvasMobileMenu = document.querySelector('#offcanvas-menu');
      offcanvasMobileMenu.classList.remove('show-mobile-menu');
    }
  }

};
</script>

<style lang="scss">
@import '../../site/assets/scss/default/_variables.scss';

.offcanvas-navigation {
  ul {
    li {
      &.menu-item-has-children {
        &.mega--title > {
          a {
            font-weight: 500;
          }
        }

        .sub-menu {
          height: 0;
          opacity: 0;
          transition: 0.3s;
          visibility: hidden;
        }

        &.active > {
          .sub-menu {
            height: 100%;
            opacity: 1;
            visibility: visible;
          }
        }
      }

      a {
        color: $white;
        font-size: 14px;
        font-weight: 600;
        padding: 10px 0;
        display: block;

        &:hover {
          color: $theme-color;
        }
      }
    }
  }

  ul {
    &.sub-menu {
      margin-left: 15px;
      transition: 0.4s;

      li {
        a {
          color: $white;
          font-weight: 400;

          &:hover {
            color: $theme-color;
          }
        }

        .sub-menu {
          li {
            a {
              font-size: 13px;
              padding: 5px 0;
            }
          }
        }
      }
    }

    li {
      &.menu-item-has-children {
        position: relative;
        display: block;

        a {
          display: inline-block;
        }

        &.active > {
          .menu-expand {
            background-color: rgba($white, 0.2);

            i {
              &:before {
                transform: rotate(0);
              }
            }
          }
        }

        .menu-expand {
          top: 5px;
          right: 0;
          width: 35px;
          height: 35px;
          cursor: pointer;
          line-height: 35px;
          position: absolute;
          text-align: center;
          background-color: rgba($white, 0.1);

          &:hover {
            background-color: rgba($white, 0.2);
          }

          i {
            display: block;
            border-bottom: 1px solid #ffffff;
            position: relative;
            width: 10px;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &:before {
              width: 100%;
              content: "";
              border-bottom: 1px solid $white;
              display: block;
              position: absolute;
              top: 0;
              transform: rotate(90deg);
              transition: 0.4s;
            }
          }
        }
      }
    }
  }
}
</style>