<template>
  <div ref="scrollTo" class="main-wrapper">
    <Header/>
    <OffCanvasMobileMenu/>
    <Breadcrumb :items="items" title="Downloads"/>
    <div class="download-main-container">
      <div class="container">
        <label class="label-style"> Project promotion </label>
      </div>
      <div :key="row.section" v-for="row in downloads" class="brook-dividers-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12" v-if="row.section === 'project promotion'">
              <div class="dividers-inner">
                <div class="space d-flex">
                  <div class="pt--20">
                    <a class="heading heading-h5 mt--35 dwnl-link" download v-bind:href="row.file_url"
                       target="_blank">
                      <img src="../../static/file-download.png" height="15px" alt="">
                      {{ row.title }}</a>
                    <div>
                      <small class="text-muted">File size: <b>{{ parseFloat(row.size).toFixed(2) }}mb</b> | File type:
                        <b>{{ row.type }}</b></small>
                    </div>
                  </div>
                </div>
                <div class="basic-thine-line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group has-label"></div>
      <div class="container">
        <label class="label-style"> Project templates </label>
      </div>
      <div :key="row.id" v-for="row in downloads" class="brook-dividers-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12" v-if="row.section === 'project templates'">
              <div class="dividers-inner">
                <div class="space d-flex">
                  <div class="pt--20">
                    <a class="heading heading-h5 mt--35 dwnl-link" download v-bind:href="row.file_url"
                       target="_blank"> <img src="../../static/file-download.png" height="15px" alt=""> {{
                        row.title
                      }}</a>
                    <div>
                      <small class="text-muted">File size: <b>{{ parseFloat(row.size).toFixed(2) }}mb</b> | File type:
                        <b>{{ row.type }}</b></small>
                    </div>
                  </div>
                </div>
                <div class="basic-thine-line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group has-label"></div>
      <div class="container">
        <label class="label-style"> Travel supporting documents </label>
      </div>
      <div :key="row.type" v-for="row in downloads" class="brook-dividers-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12" v-if="row.section === 'travel supporting documents'">
              <div class="dividers-inner">
                <div class="space d-flex">
                  <div class="pt--20">
                    <a class="heading heading-h5 mt--35 dwnl-link" download v-bind:href="row.file_url"
                       target="_blank"> <img src="../../static/file-download.png" height="15px" alt=""> {{
                        row.title
                      }}</a>
                    <div>
                      <small class="text-muted">File size: <b>{{ parseFloat(row.size).toFixed(2) }}mb</b> | File type:
                        <b>{{ row.type }}</b></small>
                    </div>
                  </div>
                </div>
                <div class="basic-thine-line"></div>
                <div class="form-group has-label"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import {downloadsCollection} from "@/firebase";

export default {
  components: {
    Breadcrumb: () => import('../../components/Breadcrumb'),
    Header: () => import('../../components/Header'),
    OffCanvasMobileMenu: () => import('../../components/OffCanvasMobileMenu'),
    Footer: () => import('../../components/Footer')
  },

  data() {
    return {
      downloads: [],
      items: [
        {
          text: 'Home',
          to: "/"
        },
        {
          text: 'Downloads',
          to: "/downloads"
        }
      ]
    }
  },
  head() {
    return {
      title: 'Dividers'
    }
  },
  firestore: {
    downloads: downloadsCollection.where('active', '==', true)
  },
  mounted() {
    this.$smoothScroll({
      scrollTo: this.$refs.scrollTo,
      duration: 400,
    });
  }
};
</script>

<style scoped>

.dwnl-link:hover {
  color: #FF4E00;
}

.download-main-container {
  padding: 100px 0;
}

.label-style {
  color: #FF4E00;
  font-weight: 600;
  line-height: 1.41;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}
</style>