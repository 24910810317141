<template>
  <div
      class="breadcrumb-area pt--300 pt_lg--400 pt_md--250 pt_sm--200 pb--75 bg_image--8 breadcrumb-title-bar breadcrumb-title-white">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb-inner text-center">
            <h2 class="heading">{{ title }}</h2>
            <div class="breadcrumb-insite">
              <b-breadcrumb class="core-breadcrumb" :items="items"></b-breadcrumb>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['items', 'title']
};
</script>

<style>
.bg_image--8 {
  background-image: url("../static/img/headerpages.jpg");
}
</style>