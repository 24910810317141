<template>
  <div>
    <base-header type="gradient-orange" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow"
               :class="type === 'dark' ? 'bg-default': ''">
            <div class="card-header border-0"
                 :class="type === 'dark' ? 'bg-transparent': ''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Partners
                  </h3>
                </div>
                <div class="col text-right">
                  <router-link to="/admin/partners/create" class="btn btn-primary"
                  ><i class="ni ni-fat-add align-middle"></i> Create new
                    partner
                  </router-link
                  >
                </div>
              </div>
            </div>


            <div class="table-responsive">
              <base-table class="table align-items-center table-flush"
                          :class="type === 'dark' ? 'table-dark': ''"
                          :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                          tbody-classes="list"
                          :data="this.partners">

                <template slot="columns">
                  <th>Title</th>
                  <th>Url</th>
                  <th>Created</th>
                  <th class="text-center">Active</th>
                  <th class="text-center">#</th>
                </template>

                <template slot-scope="{row}">

                  <th scope="row">
                    <div class="media align-items-center">
                      <div class="avatar mr-3">
                        <img alt="Image placeholder" :src="row.imageUrl">
                      </div>
                      <div class="media-body">
                        <span class="name mb-0 text-sm">{{ row.title }}</span>
                      </div>
                    </div>
                  </th>
                  <td class="budget">
                    <a v-bind:href="row.url" target="_blank">{{ row.url }}</a>
                  </td>
                  <td>
                    <badge class="badge-dot mr-4">
                      <div><small>Created by:</small> {{ row.user.firstName }}</div>
                      <div><small>Date:</small> {{ moment(row.created.toDate()).format('DD.MM.YYYY') }}</div>
                    </badge>
                  </td>
                  <td class="text-center">
                    <div class="avatar-group">
                      <toggle-button :value="row.active"
                                     color="#2BCEA0"
                                     :sync="true"
                                     :labels="true"
                                     @change="toggleActive(row.id, row.active)"/>
                    </div>
                  </td>
                  <td class="text-center">
                    <div>
                      <router-link :to="`partners/edit/${row.id}`">
                        <button class="btn btn-sm ni ni-settings btn-primary">
                        </button>
                      </router-link>
                      <button @click="passIdForDeletion(row)" v-b-modal.modal-1
                              class="btn btn-sm ni ni-fat-remove btn-danger ml-1"></button>
                    </div>
                  </td>
                </template>
              </base-table>
            </div>
          </div>
          <b-modal hide-footer id="modal-1" ref="my-modal" title="Remove partner">
            <p v-if="this.partnerForDeletion" class="mt-0 mb-4">Are you sure you want to remove partner: '<b>
              {{ this.partnerForDeletion.title }} </b>'?</p>
            <button type="button" class="btn btn-danger" @click="removePartner">Remove</button>
            <b-button @click="hideModal">Cancel</b-button>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {partnersCollection, storageRef} from "@/firebase";

export default {
  name: "partners",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      partners: [],
      partnerForDeletion: null
    };
  },
  firestore: {
    partners: partnersCollection
  },
  methods: {
    passIdForDeletion(row) {
      this.partnerForDeletion = row;
      this.$refs['my-modal'].show();
    },
    removePartner() {
      partnersCollection.doc(this.partnerForDeletion.id).get().then((doc) => {
        if (doc.exists) {
          let image = doc.data().image
          storageRef.child("partners/" + image).delete()
          partnersCollection.doc(this.partnerForDeletion.id).delete();
        }
      })
      this.$refs['my-modal'].hide();
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleActive(id, active) {
      partnersCollection.doc(id).update({active: !active});
    }
  }
};
</script>

<style scoped>
@import "~bootstrap-vue/dist/bootstrap-vue.min.css";
</style>