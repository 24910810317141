<template>

  <div id="content" style="width: 100%; height: 100%">
    <iframe v-bind:src="newsletter.url" seamless="seamless" scrolling="no" frameborder="0"
            allowtransparency="true" allowfullscreen="true"></iframe>
  </div>
</template>

<script>
import {newsletterCollection} from "@/firebase";

export default {
  components: {},
  data() {
    return {
      slug: this.$route.params.slug,
      newsletter: {}
    }
  },
  mounted() {
    document.body.classList.add('full-screen')
  },
  methods: {
    getData(slug) {
      this.blogCats = [];
      this.blogFiles = [];
      newsletterCollection.where('slug', '==', slug).get().then((result) => {
        result.forEach((item) => {
          this.newsletter = item.data();
        })
      });
    },
    back() {
      window.history.back();
    }
  },
  watch: {
    '$route.params.slug': function () {
      this.getData(this.$route.params.slug);
    }
  },
  created() {
    this.getData(this.$route.params.slug);
  },
  head() {
    return {
      title: 'Newsletter'
    }
  },
};
</script>
<style scoped>

body, html
{
  margin: 0; padding: 0; height: 100%; overflow: hidden;
}

#content
{
  position:absolute; left: 0; right: 0; bottom: 0; top: 0px;
}

iframe
{
  width: 100%;
  height: 100%;
}

</style>