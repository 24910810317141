<template>
  <div class="bk-portfolio-area">
    <div class="black-bg-wrapper bg-gradient-orange section-pt-xl pb--150">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center white-text wow move-up">
              <h2>Photo galleries</h2>
              <h4 class="mt-2 latestMeetings">from our latest meetings, conferences and other events.</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PortfolioGridBoxed/>
    <div class="row align-items-center pb--50 bg_color--1">
      <div class="col-lg-12 col-12">
        <div class="call-content vertical-call-toaction text-center">
          <div class="call-btn text-center">
            <router-link to="/galleries" class="brook-btn bk-btn-theme text-theme btn-sd-size btn-rounded nuxt-link-active bg-gradient-orange">
              See more galleries</router-link></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    PortfolioGridBoxed: () => import ("../../pages/portfolio/portfolio-grid-boxed")
  }
};
</script>

<style>
.uperr-title {
  color: #FF4E00;
  font-size: 1.5rem
}
.latestMeetings{
  color: rgba(255, 255, 255, 0.904);
  font-weight: 700;
}
</style>