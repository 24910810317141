<template>
  <div class="bk-brand-area bg_color--1 ptb--60">

    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="brand-wrapper">
            <div class="brand__list brand-default brand-style--1">
              <swiper :options="swiperOption">
                <div v-for="partner in partners" v-bind:key="partner.id" class="brand swiper-slide">
                  <a :href="partner.url" target="_blank">
                    <img :src="partner.imageUrl" alt="logo image">
                  </a>
                </div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Swiper} from 'vue-awesome-swiper';
import {partnersCollection} from "@/firebase";
import "../plugins/vue-awesome-swiper";
import "../assets/css/animation.css"

export default {
  data() {
    return {
      swiperOption: {
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 1000,
        },
        breakpoints: {
          1200: {
            slidesPerView: 5
          },

          768: {
            slidesPerView: 3

          },

          480: {
            slidesPerView: 2
          }
        }
      },
      partners: []
    }
  },
  components: {
    Swiper,
  },
  firestore: {
    partners: partnersCollection.where('active', '==', true)
  },
};
</script>

<style lang="scss">
@import "../assets/scss/default/variables";
@import "../assets/scss/elements/carousel-slider";
</style>