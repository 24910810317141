<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-orange opacity-8"></span>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card>
            <div slot="header" class="border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Create new Gallery</h3>
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">Gallery information</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <base-input alternative=""
                                  label="Title"
                                  placeholder="Gallery title..."
                                  input-classes="form-control-alternative"
                                  v-model="gallery.title"
                                  type="text"
                                  ref="titleRef"
                                  required
                      />
                    </div>
                    <div class="col-lg-12">
                      <Cropper
                          class="cropper-aria"
                          :stencil-props="{aspectRatio: 1/1}"
                          :src="featuredImgSrc"
                          ref="cropper"
                      ></Cropper>

                      <span class="mt-3 mb-3 btn btn-primary" @click="$refs.file.click()" required>
                          <input
                              style="display:none" type="file" ref="file"
                              @change="loadImage($event)"
                              accept="image/*"> Load image</span>
                    </div>

                    <div class="col-lg-12">
                      <button v-if="!galleryCreated" class="btn btn-success" @click="formValidationAndCreateGallery()">
                        Create gallery
                      </button>
                      <button v-if="galleryCreated" class="btn btn-primary" @click="editFeaturedImgAndTitle()">Update
                        gallery information
                      </button>
                      <small class="text-muted ml-2">{{ updateStatus.title }}</small>
                    </div>
                  </div>
                </div>
                <div v-if="galleryCreated">
                  <hr class="my-4"/>
                  <h6 class="heading-small text-muted mb-4">Add Images to your gallery</h6>
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-lg-12">
                        <vue-dropzone id="customdropzone" ref="imgDropZone" :options="dropzoneOptions"
                                      @vdropzone-complete="afterComplete" :useCustomSlot=true>
                          <div class="dropzone-custom-content">
                            <h3 class="dropzone-custom-title">Drag and drop to upload images!</h3>
                            <div class="subtitle">...or click to select a file from your computer</div>
                          </div>
                        </vue-dropzone>
                        <div class="text-muted mb-4"><small>Inserted photos will automatically be uploaded and saved to
                          a storage.</small></div>
                      </div>
                      <div class="col-lg-12">
                        <h6 class="heading-small text-muted mb-4">Gallery images <small>{{
                            updateStatus.images
                          }}</small>
                        </h6>
                        <div class="gallery-images">
                          <div v-for="image in images" :key="image.src" class="g-item">
                            <img :src="image.src" class="image"/>
                            <button class="btn btn-sm btn-danger ni ni-fat-remove"
                                    @click="removeImageFromGallery(image.name,image.src)"></button>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 mt-4">
                        <router-link to="/admin/galleries" class="btn btn-primary"
                        > Done!
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" ref="error-modal" title="Errors">
      <p v-if="this.errors.length">
        <b class="text-danger">Please correct the following error(s):</b>
      <ul>
        <li v-bind:key="error.id" v-for="error in errors">{{ error }}</li>
      </ul>
      </p>
    </b-modal>

  </div>

</template>
<script>
import {galleriesCollection, storageRef, usersCollection} from "@/firebase";
import {Cropper} from "vue-advanced-cropper";
import 'vue-advanced-cropper/dist/style.css';
import moment from 'moment';
import firebase from 'firebase'
// import router from '@/router';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

let slugify = require('slugify')
let uuid = require("uuid");

export default {
  name: "create",
  components: {
    vueDropzone: vue2Dropzone,
    Cropper
  },
  data() {
    return {
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        addRemoveLinks: true,
        acceptedFiles: ".jpg, .jpeg, .png"
      },
      featuredImgSrc: "",
      images: [],
      id: '',
      gallery: {
        title: '',
        slug: '',
        images: [],
        imagesUrls: [],
        count: 0,
        created: '',
        user: '',
        active: false,
        featuredImg: '',
        featuredImgUrl: ''
      },
      newFileExist: false,
      galleryCreated: false,
      errors: [],
      updateStatus: {
        title: '',
        images: ''
      }
    }
  },
  methods: {
    loadImage(event) {
      this.newFileExist = true;
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.featuredImgSrc = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    formValidationAndCreateGallery() {
      this.errors = []
      if (this.gallery.title.length > 250) {
        this.errors.push("Title cant have more than 250 chars")
      }
      if (this.gallery.title === "") {
        this.errors.push("You can't leave title empty!")
      }
      if (this.errors.length) {
        this.$refs['error-modal'].show()
        return;
      }
      this.uploadImgAndCreateGallery();
    },

    uploadImgAndCreateGallery() {
      this.gallery.featuredImg = slugify(this.gallery.title) + '-' + 'featuredImage' + moment().unix() + ".jpg"
      const {canvas} = this.$refs.cropper.getResult();
      if (canvas) {
        canvas.toBlob(blob => {
          let uploadTask = storageRef.child('galleries/' + this.gallery.featuredImg).put(blob);
          uploadTask.on('state_changed',
              (snapshot) => {
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    break;
                }
              },
              (error) => {
                console.log(error);
              },
              () => {
                this.createGallery(uploadTask)
              }
          );
        }, 'image/jpeg');
      }
    },

    createGallery(uploadTask) {
      let task = uploadTask.snapshot.ref.getDownloadURL();
      task.then((url) => {
        this.gallery.featuredImgUrl = url;
        this.gallery.user = usersCollection.doc(this.$store.state.userProfile.uid);
        this.gallery.created = firebase.firestore.Timestamp.fromDate(new Date());
        galleriesCollection.add(this.gallery).then((docRef) => {
          this.galleryCreated = true;
          this.id = docRef.id;
          this.updateStatus.title = 'Gallery created! Well done!'
          this.newFileExist = false
        });
      })
    },

    editFeaturedImgAndTitle() {
      let oldImage = this.gallery.featuredImg;
      this.gallery.featuredImg = slugify(this.gallery.title) + '-' + 'featuredImage' + moment().unix() + ".jpg"
      const {canvas} = this.$refs.cropper.getResult();
      if (canvas) {
        canvas.toBlob(blob => {
          let uploadTask = storageRef.child('galleries/' + this.gallery.featuredImg).put(blob);
          uploadTask.on('state_changed',
              (snapshot) => {
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    break;
                }
              },
              (error) => {
                console.log(error);
              },
              () => {
                storageRef.child('galleries/' + oldImage).delete();
                this.updateGalleryTitle(uploadTask)
              }
          );
        }, 'image/jpeg');
      }
    },

    updateGalleryTitle(uploadTask) {
      this.updateStatus.title = 'Gallery is updating!'
      let task = uploadTask.snapshot.ref.getDownloadURL();
      task.then((url) => {
        this.gallery.featuredImgUrl = url;
        this.gallery.user = usersCollection.doc(this.$store.state.userProfile.uid);
        this.gallery.created = firebase.firestore.Timestamp.fromDate(new Date());
        galleriesCollection.doc(this.id).update(this.gallery).then((docRef) => {
          this.galleryCreated = true;
          this.id = docRef.id;
          this.newFileExist = false
        });
        this.updateStatus.title = 'Gallery updated! Great!'
      })
    },

    uploadImageAndSaveDoc() {
      this.gallery.image = slugify(this.gallery.title) + '-' + moment().unix() + ".jpg"
      const {canvas} = this.$refs.cropper.getResult();
      if (canvas) {
        canvas.toBlob(blob => {
          let uploadTask = storageRef.child('galleries/' + this.partner.image).put(blob);
          uploadTask.on('state_changed',
              (snapshot) => {
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    break;
                }
              },
              (error) => {
                console.log(error);
              },
              () => {
                this.save(uploadTask)
              }
          );
        }, 'image/jpeg');
      }
    },
    async afterComplete(upload) {
      var imageName = uuid.v1() + ".jpg";
      try {
        var imageRef = storageRef.child(`galleries/${imageName}`)
        await imageRef.put(upload);
        var downloadURL = await imageRef.getDownloadURL();
        this.images.push({src: downloadURL, name: imageName});
        this.gallery.images.push(imageName);
        this.gallery.imagesUrls.push(downloadURL);
        this.gallery.count = this.gallery.images.length
        galleriesCollection.doc(this.id).update(this.gallery).then(() => {
          this.updateStatus.images = 'Gallery images saved and updated!'
        });
      } catch (error) {
        console.log(error);
      }
      this.$refs.imgDropZone.removeFile(upload);
    },
    async removeImageFromGallery(imageName, url) {
      try {
        await storageRef.child(`galleries/${imageName}`).delete();
        this.images = this.images.filter(function (obj) {
          return obj.name !== imageName;
        });
        this.gallery.images = this.gallery.images.filter(function (obj) {
          return obj !== imageName;
        });
        this.gallery.imagesUrls = this.gallery.imagesUrls.filter(function (obj) {
          return obj !== url;
        });
        this.gallery.count = this.gallery.images.length
        galleriesCollection.doc(this.id).update(this.gallery).then(() => {
          this.updateStatus.images = 'Gallery updated! Image is removed!'
        });
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>
<style>
.btn input {
  display: none;
}

.dropzone-custom-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #00b782;
}

.subtitle {
  color: #314b5f;
}

.gallery-images {
  padding: 20px 0;
  background-color: #fafafa;
}

.gallery-images .g-item {
  display: inline-block;
  background-color: #ffffff;
  padding: 5px;
  margin: 10px;
  vertical-align: top;
}

.gallery-images .g-item button {
}

.gallery-images .image {
  width: auto;
  height: 100px;
  margin: 5px;
}

.cropper-aria {
  border: solid 1px #EEE;
  height: 300px !important;
  width: 100%;
}

</style>