<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import "../assets/scss/style.scss"

export default {
  components: {}
}
</script>

<style>
</style>
