<template>
    <div class="main-container">

        
        <div class="brook-portfolio-area ptb--100 ptb-md--80 ptb-sm--60 bg_color--1">
            <PortfolioItemCenterCaption/>
        </div>

    </div>
</template>

<script>

    export default {
        components: {
            PortfolioItemCenterCaption: () => import('../../components/PortfolioItemCenterCaption'),
        },
        
        data () {
            return {
            }
        },

        mounted () {
            document.body.classList.add('template-color-1', 'template-font-1')
        },

        head() {
            return {
                title: 'Portfolio Grid – Boxed'
            }
        },
    };
</script>