<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-orange opacity-8"></span>
    </base-header>
    <div class="container-fluid mt--5">
      <div class="card shadow">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">
                Newsletter
              </h3>
            </div>
            <div class="col text-right">
              <router-link to="/admin/newsletter/create" class="btn btn-primary">
                <i class="ni ni-fat-add align-middle"></i> Create new
              </router-link>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <base-table class="table align-items-center table-flush" :data="this.newsletters">
            <template slot="columns">
              <th>Title</th>
              <th>Created</th>
              <th>Active</th>
              <th class="text-center">#</th>
            </template>

            <template slot-scope="{row}">
              <th scope="row">
                <div class="media align-items-center">
                  <div class="media-body">
                    <span class="text-wrap name mb-0 text-sm">{{ row.title }}</span>
                    <div><small>
                      <router-link :to="`/newsletters/${row.slug}`">/#/newsletters/{{ row.slug }}</router-link>
                    </small></div>
                    <div>
                    </div>
                  </div>
                </div>
              </th>
              <td>
                <div><small>Created by:</small> {{ row.user.firstName }}</div>
                <div><small>Date:</small> {{ moment(row.created.toDate()).format('DD.MM.YYYY') }}</div>
              </td>
              <td>
                <div class="d-flex align-items-center text-center">
                  <toggle-button :value="row.active"
                                 color="#2BCEA0"
                                 :sync="true"
                                 :labels="true"
                                 @change="toggleActive(row.id,row.active)"/>
                </div>
              </td>
              <td>
                <div class="text-center">
                  <router-link :to="`newsletter/edit/${row.id}`">
                    <button class="btn btn-sm btn-primary ni ni-settings">
                    </button>
                  </router-link>
                  <button v-if="!row.locked" class="btn btn-sm btn-danger ni ni-fat-remove ml-1" id="show-btn"
                          @click="passIdForDeletion(row)"></button>
                </div>
              </td>
            </template>

          </base-table>
          <b-modal hide-footer id="modal-1" ref="my-modal" title="Remove text page">
            <p v-if="this.newslettersForDeletion" class="mt-0 mb-4">Are you sure you want to remove this text page:
              '<b>{{ this.newslettersForDeletion.title }}</b>'?</p>
            <button type="button" class="btn btn-danger" @click="onDelete">Remove</button>
            <b-button @click="hideModal">Cancel</b-button>
          </b-modal>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {newsletterCollection} from "@/firebase"

export default {
  name: 'Newsletter',
  components: {},

  data() {
    return {
      newsletters: [],
      active: null,
      newslettersForDeletion: null
    }
  },
  firestore: {
    newsletters: newsletterCollection.orderBy('order', 'desc')
  },
  methods: {
    onDelete() {
      newsletterCollection.doc(this.newslettersForDeletion.id).get().then((doc) => {
        if (doc.exists) {
          newsletterCollection.doc(this.newslettersForDeletion.id).delete();
        }
      })
      this.$refs['my-modal'].hide()
    },
    toggleActive(id, active) {
      newsletterCollection.doc(id).update({active: !active})
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    passIdForDeletion(row) {
      this.newslettersForDeletion = row;
      this.$refs['my-modal'].show();
    },
  }
};
</script>
<style>
@import "~bootstrap-vue/dist/bootstrap-vue.min.css";

.width-100 {
  width: 100px;
}

.tag-item {
  padding: 3px 5px;
  background-color: #6286f3;
  color: #fafafa;
  margin-right: 3px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 9px;
}

.type-item {
  padding: 3px 5px;
  background-color: #6286f3;
  color: #fafafa;
  margin-right: 3px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 9px;
}

.type-item.meeting-type {
  background-color: #417a94;
}

.type-item.activity {
  background-color: #3d9d92;
}

.type-item.page {
  background-color: #5a3d9d;
}

.type-item.meeting {
  background-color: #cd3f08;
}

.type-item.section {
  background-color: #08cd7e;
}
</style>