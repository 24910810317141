<template>

  <div id="content" style="width: 100%; height: 100%">
    <iframe src="https://online.fliphtml5.com/wdqpa/czgs/?1650355954728" seamless="seamless" scrolling="no" frameborder="0"
            allowtransparency="true" allowfullscreen="true"></iframe>
  </div>
</template>

<script>
export default {
  components: {},

  mounted() {
    document.body.classList.add('full-screen')
  },

  head() {
    return {
      title: 'Brook || About Us 01'
    }
  },
};
</script>
<style scoped>

body, html
{
  margin: 0; padding: 0; height: 100%; overflow: hidden;
}

#content
{
  position:absolute; left: 0; right: 0; bottom: 0; top: 0px;
}

iframe
{
  width: 100%;
  height: 100%;
}

</style>