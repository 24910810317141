<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-orange opacity-8"></span>
    </base-header>
    <div class="container-fluid mt--5">
      <div class="card shadow">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">
                Blogs / News
              </h3>
            </div>
            <div class="col text-right">
              <router-link to="/admin/blogs/create" class="btn btn-primary">
                <i class="ni ni-fat-add align-middle"></i> Create new
              </router-link>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <base-table class="table align-items-center table-flush" :data="this.blogs">
            <template slot="columns">
              <th>Image</th>
              <th>Details</th>
              <th>Created</th>
              <th>Active</th>
              <th class="text-center">#</th>
            </template>

            <template slot-scope="{row}">
              <td>
                <img :src="row.imageUrl" class="width-100" alt="Blog Image">
              </td>
              <td scope="row">
                <div class="media align-items-center">
                  <div class="media-body">
                    <div>
                      <small class="type-item" :class="row.type">
                        {{ row.type }}
                      </small>
                    </div>
                    <div>
                      <span class="text-wrap name mb-0 text-sm"><b> {{ row.title | str_limit(200) }}</b></span>
                      <div>
                        <small v-for="tag in row.tags" v-bind:key="tag" class="tag-item">
                          {{ tag }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div><small>Created by:</small> {{ row.user.firstName }}</div>
                <div><small>Date:</small> {{ moment(row.created.toDate()).format('DD.MM.YYYY') }}</div>
              </td>
              <td>
                <div class="d-flex align-items-center text-center">
                  <toggle-button :value="row.active"
                                 color="#2BCEA0"
                                 :sync="true"
                                 :labels="true"
                                 @change="toggleActive(row.id,row.active)"/>
                </div>
              </td>
              <td>
                <div class="text-center">
                  <router-link :to="`blogs/edit/${row.id}`">
                    <button class="btn btn-sm btn-primary ni ni-settings">
                    </button>
                  </router-link>
                  <button class="btn btn-sm btn-danger ni ni-fat-remove ml-1" id="show-btn"
                          @click="passIdForDeletion(row)"></button>
                </div>
              </td>
            </template>

          </base-table>
          <b-modal hide-footer id="modal-1" ref="my-modal" title="Remove blog">
            <p v-if="this.blogForDeletion" class="mt-0 mb-4">Are you sure you want to remove this blog:
              '<b>{{ this.blogForDeletion.title }}</b>'?</p>
            <button type="button" class="btn btn-danger" @click="onDelete">Remove</button>
            <b-button @click="hideModal">Cancel</b-button>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {blogsCollection, storageRef} from "@/firebase"

export default {
  name: 'Blogs',
  data() {
    return {
      blogs: [],
      active: null,
      blogForDeletion: null
    }
  },
  firestore: {
    blogs: blogsCollection
  },
  methods: {
    onDelete() {
      blogsCollection.doc(this.blogForDeletion.id).get().then((doc) => {
        if (doc.exists) {
          if (doc.data().files.length) {
            for (let file in doc.data().files) {
              storageRef.child('blogs/' + doc.data().files[file].fileName).delete()
            }

          }
          storageRef.child('blogs/' + doc.data().image).delete();
          blogsCollection.doc(this.blogForDeletion.id).delete();
        }
      })
      this.$refs['my-modal'].hide()
    },
    toggleActive(id, active) {
      blogsCollection.doc(id).update({active: !active})
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    passIdForDeletion(row) {
      this.blogForDeletion = row;
      this.$refs['my-modal'].show();
    },
  }
};
</script>
<style>
@import "~bootstrap-vue/dist/bootstrap-vue.min.css";

.width-100 {
  width: 100px;
}

.tag-item {
  padding: 3px 5px;
  background-color: #6286f3;
  color: #fafafa;
  margin-right: 3px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 9px;
}

.type-item {
  padding: 3px 5px;
  background-color: #6286f3;
  color: #fafafa;
  margin-right: 3px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 9px;
}

.type-item.blog {
  background-color: #3d9d92;
}

.type-item.news {
  background-color: #9a3dab;
}
</style>