<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-orange opacity-8"></span>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <div class="alert-danger alert" v-if="headerDontExist">Header does not exist!</div>
          <card>
            <div slot="header" class="border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Edit header</h3>
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">header information</h6>
                <div class="pl-lg-4 mx-auto">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input alternative=""
                                  label="Title"
                                  input-classes="form-control-alternative"
                                  v-model="header.title"
                                  type="text"
                      />
                      <base-input alternative=""
                                  label="Link for official website"
                                  input-classes="form-control-alternative"
                                  v-model="header.description"
                      />
                    </div>

                    <div class="col-lg-6">
                      <img style="width:150px; border-radius:5px" :src="header.imageUrl" alt="">
                      <h6 class="mt-2 text-muted mb-4">header's current image</h6>

                    </div>
                  </div>
                </div>
                <hr class="my-4"/>
                <!-- Address -->
                <div v-if="this.newFileExists">
                  <h6 class="heading-lg text-muted text-center">Please crop the image (aspect ratio: 16:9).</h6>
                </div>
                <div v-else>
                  <h6 class="heading-small text-muted">Update image:</h6>

                </div>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <cropper
                          class="cropper-aria"
                          :stencil-props="{aspectRatio: 16/9}"
                          :src="image.src"
                          ref="cropper"
                      ></cropper>
                      <div>
                        <small class="text-muted">Please, insert image with min resolution of 1920:1080
                          (FullHD).</small>
                      </div>
                      <span class="mt-3 btn btn-primary" @click="$refs.file.click()" required>
                          <input style="display:none" type="file" ref="file" @change="loadImage($event)"
                                 accept="image/*"> Load image </span>
                    </div>
                  </div>
                </div>
                <hr class="my-4"/>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <button class="btn btn-success" @click="editHeader()">Save</button>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>

  </div>

</template>


<script>
import {headersCollection, storageRef, usersCollection} from "@/firebase";
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import moment from 'moment';
import firebase from 'firebase'
import router from '@/router';

export default {
  name: "edit",
  components: {
    Cropper
  },
  data() {
    return {
      headerDontExist: false,
      header: {
        title: '',
        description: '',
        image: '',
        imageUrl: '',
        created: '',
        user: '',
        active: false
      },
      id: this.$route.params.id,
      image: {
        src: ''
      },
      newFileExists: false
    }
  },

  methods: {
    loadImage(event) {
      this.newFileExists = true;
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.image.src = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    editHeader() {
      this.header.user = usersCollection.doc(this.$store.state.userProfile.uid);
      this.header.created = firebase.firestore.Timestamp.fromDate(new Date());

      if (this.newFileExists) {
        let oldImage = this.header.image;
        var slugify = require('slugify')
        this.header.image = slugify(this.header.title) + '-' + moment().unix() + ".jpg"
        const {canvas} = this.$refs.cropper.getResult();
        if (canvas) {
          canvas.toBlob(blob => {
            let uploadTask = storageRef.child('headers/' + this.header.image).put(blob);
            uploadTask.on('state_changed',
                (snapshot) => {
                  switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                      break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                      break;
                  }
                },
                (error) => {
                  console.log(error);
                },
                () => {
                  storageRef.child('headers/' + oldImage).delete();
                  this.save(uploadTask)
                }
            );
          }, 'image/jpeg');
        }
      } else {
        headersCollection.doc(this.id).update(this.header)
        router.push("/admin/headers")
      }
    },
    save(uploadTask) {
      let task = uploadTask.snapshot.ref.getDownloadURL();
      task.then((url) => {
        this.header.imageUrl = url;
        headersCollection.doc(this.id).update(this.header)
            .then(() => {
              router.push('/admin/headers');
            });
      })
    },

  },
  created() {
    headersCollection.doc(this.id).get().then((doc) => {
      if (doc.exists) {
        this.header = doc.data()
      }
    }).catch(() => {
      this.headerDontExist = true;
    });
  }
}
</script>

<style scoped>
.cropper-aria {
  border: solid 1px #EEE;
  height: 400px;
  width: 100%;
}
</style>