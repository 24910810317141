<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-orange opacity-8"></span>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
      </div>
    </div>
    <div class="col-xl-12 pl-5 pr-5 order-xl-1">
      <card shadow type="secondary">
        <div slot="header" class="bg-white border-0">
          <div class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">New Blog</h3>
            </div>
          </div>
        </div>
        <template>
          <form @submit.prevent>
            <h6 class="heading-small text-muted mb-4">Blog info</h6>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-lg-6">
                  <base-input alternative=""
                              label="Title"
                              placeholder="Example"
                              input-classes="form-control-alternative"
                              v-model="blog.title"
                              required
                  />
                </div>
                <div class="col-lg-6">
                  <base-input alternative=""
                              label="Related link"
                              placeholder="Link that blog refers to"
                              input-classes="form-control-alternative"
                              v-model="blog.relatedLink"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3">
                  <label class="form-control-label">Blog type *</label> <br>
                  <b-form-select v-model="selected" :options="options"></b-form-select>
                </div>
                <div class="col-lg-3">
                  <label class="form-control-label">Related date *</label>
                  <b-form-datepicker alternative=""
                                     placeholder="Date related to blog"
                                     class="form-control-alternative"
                                     v-model="blog.date" required
                  />
                </div>
                <div class="col-lg-6">
                  <label class="form-control-label">Tags *</label>
                  <b-form-tags class="form-control-alternative" tag tag-pills remove-on-delete tag-variant="primary"
                               placeholder="Type a new tag and press enter" input-id="tags"
                               v-model="blog.tags"></b-form-tags>
                </div>
              </div>
            </div>
            <hr class="my-4"/>
            <!-- Image -->
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-lg-12">
                  <label class="form-control-label">Cover image *</label>
                  <cropper
                      class="cropper-aria"
                      :stencil-props="{aspectRatio: 16/9}"
                      :src="image.src"
                      ref="cropper"
                  ></cropper>
                  <span class="mt-3 btn btn-primary" @click="$refs.image.click()" required><input
                      style="display:none" type="file" ref="image"
                      @change="loadImage($event)"
                      accept="image/*"> Load image</span>
                </div>

              </div>
            </div>
            <hr class="my-4"/>
            <!-- Files -->
            <div class="pl-lg-4">
              <label class="form-control-label">Files:</label><br>
              <small>Please, after adding new file click on button '+ Save file' to actually save file. (File is saved if it is in Uploaded section below)</small><br><br>
              <div class="row">
                <div class="col-lg-5">
                  <base-input alternative=""
                              label="File desription (Name) *"
                              placeholder="Descriptio or name of the file..."
                              input-classes="form-control-alternative"
                              v-model="blogFile.name"
                  />
                </div>
                <div class="col-lg-3">
                  <label class="form-control-label">File type *</label> <br>
                  <b-form-select v-model="blogFile.type" :options="blogFileCategories"></b-form-select>
                </div>
                <div class="col-lg-2">
                  <label class="form-control-label">File *</label> <br>
                  <input type="file" ref="file">
                </div>
                <div class="col-lg-2">
                  <label class="form-control-label"></label><br>
                  <button class="btn btn-primary" @click="saveFile()" :disabled="isDisabled"><i
                      class="ni ni-fat-add align-middle"></i> Save
                    file
                  </button>
                </div>

              </div>
              <table>
                <th>Uploaded:</th>
                <th></th>
                <tr :key="file.name" v-for="(file,index) in blogFiles">
                  <td class="">
                    <a target="_blank" :href="file.fileUrl" :download="file.name">
                      {{ file.name }}
                    </a>
                  </td>
                  <td>
                    <button class="btn btn-sm btn-danger ni ni-fat-remove ml-1"
                            @click="removeFile(index, file.ref,file.refName)"
                            title="Remove"></button>
                  </td>
                </tr>
              </table>
            </div>
            <hr class="my-4"/>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-lg-11" style="display:inline;">
                  <label class="form-control-label">Short brief *</label>
                </div>
                <!--                <div class="col-lg-1 input-group-addon text-right" style="display: inline"-->
                <!--                     v-text="'('+(max - blog.brief.length)+')'"></div>-->
                <div class="col-lg-12">
                  <div class="form-group has-label">
                    <b-textarea
                        placeholder="..."
                        v-model="blog.brief"
                        :maxlength="max"
                        rows="4"
                        required
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr class="my-4"/>
            <!-- Description -->
            <h6 class="heading-small text-muted mb-4">Main text</h6>
            <div class="pl-lg-4">
              <div class="form-group">
                <label class="form-control-label">Text *</label>

              </div>
              <wysiwyg class="wysiwyg" v-model="blog.content"/>
            </div>
            <button class="mt-7 btn btn-primary" :disabled="isDisabled" @click="formValidation">{{ clicked }}</button>
          </form>
        </template>
      </card>
      <b-modal id="modal-1" ref="greske" title="Errors">
        <p v-if="this.errors.length">
          <b>Please correct the following error(s):</b>
        <ul>
          <li v-bind:key="error.id" v-for="error in errors">{{ error }}</li>
        </ul>
        </p>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import firebase from "firebase"
import {blogsCollection, usersCollection, configurationsCollection, blogsFilesCollection} from "@/firebase";
import {storageRef} from "@/firebase"
import moment from 'moment'
import router from "@/router"

export default {
  name: 'user-profile',
  components: {
    Cropper,
  },
  data() {
    return {
      image: {
        src: '',
        imageLoaded: false
      },
      selected: null,
      options: [
        {value: 'blog', text: 'Blog'},
        {value: 'news', text: 'News'},
      ],
      blogFileCategories: [],
      blogFiles: [],
      blogFile: {
        ref: '',
        refName: '',
        name: '',
        type: '',
        file: null,
        fileUrl: '',
        user: '',
        created: ''
      },
      blog: {
        type: '',
        title: '',
        slug: '',
        relatedLink: '',
        date: new Date(),
        tags: [],
        brief: ' ',
        content: '',
        image: '',
        imageUrl: null,
        files: [],
        active: true,
        user: '',
        created: '',
        blogFiles: []
      },
      type: '',
      max: 500,
      value: [],
      files: [],
      errors: [],
      clicked: "Create blog",
      isDisabled: false
    }
  },
  methods: {
    formValidation() {
      this.isDisabled = true
      this.clicked = "Creating..."
      this.errors = []
      if (this.blog.title.length > 250) {
        this.errors.push("Title cant have more than 250 chars")
      }
      if (this.blog.title === "") {
        this.errors.push("You can't leave title empty")
      }
      if (this.blog.relatedLink.length > 1) {
        //eslint-disable-next-line
        var regex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
        //eslint-disable-next-line
        if (!regex.test(this.blog.relatedLink)) {
          this.errors.push("You must add valid url")
        }
        if (this.blog.relatedLink.length > 100) {
          this.errors.push(("URL is too long, we suggest using link shortener"))
        }
      }
      if (!this.selected) {
        this.errors.push("You must enter the type of blog")
      }
      if (this.blog.tags.length === 0) {
        this.errors.push("You must add at least 1 tag")
      }
      if (!this.image.imageLoaded) {
        this.errors.push("You must add blog image")
      }
      if (this.blog.brief.length === 0) {
        this.errors.push("Blog must have brief")
      }
      if (this.blog.brief.length > 500) {
        this.errors.push("Brief can't be longer than 500 chars")
      }
      if (this.blog.content.length === 0) {
        this.errors.push("Blog must have content")
      }
      if (this.errors.length > 0) {
        this.$refs['greske'].show()
        this.isDisabled = false
        this.clicked = "Create blog"
        return;
      }
      this.saveFiles()
    },
    uploadImageFile() {
      var slugify = require('slugify')
      let imageName = this.$refs.image.files[0].name.split(".")[0]
      let ext = this.$refs.image.files[0]['name'].split(".")[1]
      this.blog.image = slugify(imageName) + "-" + moment().unix() + "." + ext
      const {canvas} = this.$refs.cropper.getResult();
      if (canvas) {
        canvas.toBlob(blob => {
          let uploadTask = storageRef.child('blogs/' + this.blog.image).put(blob)
          uploadTask.on('state_changed',
              (snapshot) => {
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    break;
                }
              },
              (error) => {
                console.log(error);
              },
              () => {
                this.save(uploadTask)
              }
          );
        }, 'image/jpeg');
      }
    },
    save(uploadTask) {
      let task = uploadTask.snapshot.ref.getDownloadURL();
      task.then((url) => {
        this.blog.imageUrl = url
        let slugify = require("slugify")
        this.blog.slug = slugify(this.blog.title.toLowerCase())
        this.blog.user = usersCollection.doc(this.$store.state.userProfile.uid)
        this.blog.active = false
        this.blog.created = firebase.firestore.Timestamp.fromDate(new Date())
        this.blog.type = this.selected
        if (this.blogFiles.length > 0) {
          for (const file in this.blogFiles) {
            this.blog.blogFiles.push(blogsFilesCollection.doc(this.blogFiles[file].ref));
          }
        }

        blogsCollection.add(this.blog).then(() => {
          router.push('/admin/blogs');
        }).catch((error) => {
          console.error("Error adding document: ", error);
        });
      })
    },
    async saveFiles() {
      this.uploadImageFile()
    },
    handleFileInput() {
      let files = this.$refs.file.files
      if (!files) return;
      ([...files]).forEach(f => {
        this.files.push(f);
      });
    },
    loadImage(event) {
      this.image.imageLoaded = true
      var input = event.target
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.image.src = e.target.result
        };
        reader.readAsDataURL(input.files[0])
      }
    },
    removeFile(index, ref, refName) {
      this.blogFiles.splice(index, 1)
      storageRef.child('blogs/' + refName).delete()
      blogsFilesCollection.doc(ref).delete();
    },
    async saveFile() {
      this.isDisabled = true
      this.errors = []
      if (this.blogFile.name.length > 250) {
        this.errors.push("File title cant have more than 250 chars")
      }
      if (this.blogFile.name === "") {
        this.errors.push("You can't leave file title empty")
      }
      if (this.blogFile.type === "") {
        this.errors.push("You can't leave file type empty")
      }
      let files = this.$refs.file.files
      if (files.length === 0) {
        this.errors.push("You can't leave file input empty")
      }
      if (this.errors.length > 0) {
        this.$refs['greske'].show()
        this.isDisabled = false
        return;
      }
      ([...files]).forEach(f => {
        this.blogFile.file = f;
      });

      let slugify = require("slugify")
      let timestamp = new Date()
      let fileObject = this.blogFile.file
      let ext = fileObject.name.split(".")[1]
      let fileNameName = slugify(fileObject.name.split(".")[0]) + "-" + timestamp.getTime().toString() + "." + ext
      try {
        let uploadTask = storageRef.child('blogs/' + fileNameName)
        await uploadTask.put(fileObject)
        let downloadURL = await uploadTask.getDownloadURL()
        this.blogFile.refName = fileNameName;
        this.blogFile.fileUrl = downloadURL;
      } catch (error) {
        console.log(error)
      }

      this.blogFile.user = usersCollection.doc(this.$store.state.userProfile.uid)
      this.blogFile.created = firebase.firestore.Timestamp.fromDate(new Date())
      delete this.blogFile.file
      blogsFilesCollection.add(this.blogFile).then((data) => {
        this.blogFile.ref = data.id;
        this.blogFiles.push(this.blogFile);
        this.blogFile = {
          ref: '',
          refName: '',
          name: '',
          type: '',
          file: null,
          fileUrl: '',
          user: '',
          created: ''
        };

        this.isDisabled = false
      }).catch((error) => {
        this.errors.push(error)
        this.$refs['greske'].show()
        this.isDisabled = false
        return;
      });
    }
  },
  created() {
    configurationsCollection.doc('blog_files_categories').get().then(async (doc) => {
      if (doc.exists) {
        let cats = doc.data().name;

        for (const c in cats) {
          this.blogFileCategories.push({
            value: cats[c],
            text: cats[c]
          })
        }
      }
    }).catch((error) => {
      console.log(error)
      this.blogDoesntExist = true
    })
  }

};
</script>
<style>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";

.cropper-aria {
  border: solid 1px #EEE;
  background-color: white;
  height: 300px;
  width: 100%;
}

.dropbox {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: ghostwhite;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px;
  position: relative;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 200px;
  position: relative;
  cursor: pointer;
}

.dropbox:hover {
  background: #bacec6;
}

.p-class {
  display: inline;
  font-size: 1.2em;
  text-align: center;
  padding: 20px 0;
}

.wysiwyg {
  height: 100%;
  background-color: white;
}
</style>
