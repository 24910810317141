<template>
  <div ref="scrollTo" class="main-container">
    <Header/>
    <OffCanvasMobileMenu/>
<!--    <Breadcrumb :items="items" title=""/>-->
    <div
        class="breadcrumb-area pt--300 pt_lg--400 pt_md--250 pt_sm--200 pb--75 bg_image--8 breadcrumb-title-bar breadcrumb-title-white">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrumb-inner text-center">
              <h2 class="heading">Photo galleries</h2>
              <h2 class="heading-section text-white">from our latest meetings, conferences and other events</h2>
              <div class="breadcrumb-insite">
                <b-breadcrumb class="core-breadcrumb" :items="items"></b-breadcrumb>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="brook-portfolio-area ptb--100 ptb-md--80 ptb-sm--60 bg_color--1">
      <div class="container">
        <div class="row mtn--30">
          <div v-for="gallery in galleries" v-bind:key="gallery.data.id" class="col-lg-4 col-sm-6 move-up wow mt--30">
            <router-link :to="`galleries/g/${gallery.id}`">
              <div class="portfolio portfolio_style--1">
                <div class="thumb">
                  <img :src="gallery.data.featuredImgUrl" alt="Portfolio Image">
                </div>
                <div class="port-overlay-info">
                  <div class="hover-action">
                    <h3 class="post-overlay-title">
                      {{ gallery.data.title }}
                    </h3>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="text-center mt-5">
          <div class="basic-dark2-line-1px mb-4"></div>
          <button v-if="haveMore" class="btn load-more bg-gradient-orange" @click="onPageChange">load more</button>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import {galleriesCollection} from "@/firebase";

export default {
  components: {
    Header: () => import('../../components/Header'),
    OffCanvasMobileMenu: () => import('../../components/OffCanvasMobileMenu'),
    // Breadcrumb: () => import('../../components/Breadcrumb'),
    Footer: () => import('../../components/Footer'),
  },
  data() {
    return {
      perPage: 6,
      last: null,
      galleries: [],
      haveMore: true,
      items: [
        {
          text: 'Home',
          to: "/"
        },
        {
          text: 'Galleries',
          to: "/galleries"
        }
      ]
    }
  },
  created() {
    galleriesCollection.where('active', '==', true).orderBy('created', "desc")
        .limit(this.perPage).get()
        .then((query) => {
          if (query.size < this.perPage) {
            this.haveMore = false;
          }
          query.forEach((item) => {
            this.galleries.push({
              id: item.id,
              data: item.data()

            })
            this.last = item
            this.$smoothScroll({
              scrollTo: this.$refs.scrollTo,
              duration: 400,
            });
          })
        })
  }, methods: {
    onPageChange() {
      galleriesCollection.where('active', '==', true).limit(this.perPage)
          .orderBy("title").startAfter(this.last)
          .get()
          .then((query) => {
            if (query.size < this.perPage) {
              this.haveMore = false;
            }
            query.forEach((item) => {
              console.log(item);
              this.galleries.push({
                id: item.id,
                data: item.data()
              })
              this.last = item
            })
          })
    }
  },
  mounted() {
    document.body.classList.add('template-color-1', 'template-font-1')
  },
};
</script>

<style scoped>
.bg_image--8 {
  background-image: url("../../static/img/headerpages.jpg");
}

.port-overlay-info {
  background-color: #FF4E00 !important;
}

.load-more {
  font-weight: 700;
  padding: 10px 50px;
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  outline: none;
  color: white;
}

.load-more:hover {
  background-color: #ca3902;
}
</style>