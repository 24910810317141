<template>
  <div ref="scrollTo" class="main-container">

    <Header/>
    <OffCanvasMobileMenu/>
    <Breadcrumb :items="items" title="Participants"/>
    <div class="container mt-5 mb-5">
      <div class="brook-service-list-area" v-for="partner in partners" v-bind:key="partner.id" height="100px">
        <div class="bk-service-list d-block d-md-flex mb--70 align-items-center wow move-up">
          <div class="thumb">
            <a :href="partner.url" target="_blank">
              <img :src="partner.imageUrl" class="w--100"></a>
          </div>
          <div class="content plr--130 plr_md--50 plr_sm--40">
            <p class="text-muted">Participant's name:</p>
            <h3 class="partner-title heading heading-h3">{{ partner.title }}</h3>
            <p class="text-muted mt-3">Participant's official website:</p>
            <a class="text-black mt-n3" :href="partner.url" target="_blank">{{ partner.url }}</a>
            <div class="bkseparator--35"></div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import {partnersCollection} from "@/firebase";

export default {
  components: {
    Header: () => import('../components/Header'),
    OffCanvasMobileMenu: () => import('../components/OffCanvasMobileMenu'),
    Breadcrumb: () => import('../components/Breadcrumb'),
    Footer: () => import('../components/Footer'),
  },

  data() {
    return {
      partners: [],
      items: [
        {
          text: 'Home',
          to: "/"
        },
        {
          text: 'Participant',
          to: "/participants"
        }
      ]
    }
  },

  head() {
    return {
      title: 'Brook || Service List'
    }
  },
  firestore: {
    partners: partnersCollection.where('active', '==', true).orderBy('order','asc')
  },
  mounted(){
    this.$smoothScroll({
            scrollTo: this.$refs.scrollTo,
            duration: 400,
          });
  }
};
</script>

<style scoped>
a:hover {
  color: blue !important;
}

a {
  color: black !important;
  margin-top: -10px;
}

h3 {
  color: #FF5B13 !important;
}

.thumb {
  transition: 0.5s;
}

.thumb:hover {
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.048);
}

.text-black {
  margin: 0 !important;
}
.partner-title{
  letter-spacing: 2px;
  text-transform: none;
}
</style>