<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-orange opacity-8"></span>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card>
            <div slot="header" class="border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Choose header image</h3>
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">All header images</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="gallery-images rounded">
                        <div v-for="image in headers" :key="image" class="g-item rounded">

                          <img :src="image" class="image" alt=""/>
                          <div v-if="header.image !== image" class="btn-box rounded">
                            <button class="btn btn-sm btn-success ni ni-check-bold"
                                    @click="changeHeader(image)"></button>
                            <small class="text-muted">Set this header as a current.</small>
                          </div>
                          <div v-if="header.image === image" class="current-header text-muted">
                            <div>This is current header image. Last update:
                              {{ moment(header.created.toDate()).format('DD.MM.YYYY') }}, by {{
                                header.user.firstName
                              }}.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {configurationsCollection, storageRef, usersCollection} from "@/firebase";
import firebase from 'firebase'

export default {
  name: "header-config",
  data() {
    return {
      headers: [],
      header: {
        image: '',
        created: '',
        user: ''
      }
    }
  },
  firestore: {
    header: configurationsCollection.doc('header')
  },
  created() {
    storageRef.child('static/headers').listAll().then((res) => {
      res.items.forEach((itemRef) => {
        itemRef.getDownloadURL().then((url) => {
          this.headers.push(url);
        })
      });
    })
  },
  methods: {
    changeHeader(imageUrl) {
      this.header.image = imageUrl;
      this.header.user = usersCollection.doc(this.$store.state.userProfile.uid);
      this.header.created = firebase.firestore.Timestamp.fromDate(new Date());
      configurationsCollection.doc("header").update(this.header);
    }
  }
};
</script>
<style>
.btn-box {
  padding: 2px 3px;
}

.current-header div {
  padding: 8px 5px;
  font-size: 10px;
}

.gallery-images {
  background-color: #fafafa;
  padding: 20px 0;
}

.gallery-images .g-item {
  display: inline-block;
  background-color: #ffffff;
  padding: 5px;
  margin: 10px;
  vertical-align: top;
}

.gallery-images .image {
  width: auto;
  height: 200px;
  margin: 5px;
}

@media only screen and (max-width: 700px) {

  .gallery-images .image {
    width: 100%;
    height: auto;
    margin: 5px;
  }

}

</style>