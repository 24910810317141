<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-orange opacity-8"></span>
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">Hello {{ userProfile.firstName }} {{ userProfile.lastName }}</h1>
            <p class="text-white mt-0 mb-5">Digital research centres and universities have a key leading role in
              digitization process of industries, strengthening cooperation with national economies, capable to provide
              expert knowledge and support to their local industries, as well as to help them to cooperate with
              innovators, investors and customers all across Europe and globally. However, collaboration and networking
              are also essential to ensure best practices can be exported to other regions, and missing expertise can be
              imported, ensuring an efficient innovation ecosystem.</p>
          </div>
        </div>
      </div>
    </base-header>
  </div>
</template>
<script>
import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState(['userProfile'])
  },
  data() {
    return {};
  },
  mounted() {
    if(document.body.classList.contains('refresh-page')){
      location.reload();
    }
  }
};
</script>
<style></style>
